import React from 'react';
import clsx from 'clsx';

interface Props extends React.ComponentProps<'button'> {
  onToggle: () => void;
  isOn: any;
  disabled?: boolean;
  id?: string;
}

export default function Switch({
  onToggle,
  isOn,
  disabled,
  id,
  ...buttonProps
}: Props) {
  return (
    <button
      className={clsx(
        'h-2.25 w-4.25 rounded-full p-px transition-colors duration-200',
        isOn ? 'bg-socialiePink' : 'bg-darkGreyBg',
        disabled ? 'opacity-50' : 'cursor-pointer'
      )}
      onClick={onToggle}
      disabled={disabled}
      id={id}
      type="button"
      {...buttonProps}
    >
      <span
        className={clsx(
          'flex items-center transition-transform duration-200',
          isOn && 'translate-x-2'
        )}
      >
        <span className="h-2 w-2 rounded-full bg-white shadow-sm" />
      </span>
    </button>
  );
}
