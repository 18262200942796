import { rgba } from 'polished';

const primitives = {
  grey1: '#101820',
  grey2: '#1c252f',
  grey3: '#343d46',
  grey4: '#5a636e',
  grey5: '#9198a1',
  grey6: '#c9ced4',
  grey7: '#dfe2e6',
  grey8: '#f0f2f5',
  grey9: '#f5f7fa',

  white: '#fff',
  black: '#000',
  teal: '#46b4b4',
  darkTeal: '#46b4b4',
  tealBg: '#eff8f8',
  yellow: '#ff9f40',
  yellowBg: '#fff5eb',
  blue: '#257CFF',
  blueBg: 'rgba(37, 124, 255, 0.1)',
  red: '#ed6c85',
  redBg: '#fdeff2',
  redBgLight: '#fff7f9',
  green: '#4bc0c0',
  purple: '#7A58FF',
  purpleHover: '#654BD2',

  error: '#ff6383',
  errorBg: '#ffeded',

  facebook: '#1877f2',
  instagram: '#e73558',
  instagram_graph: '#e73558',
  instagram_basic: '#e73558',
  instagram_stories: '#e73558',
  linkedin: '#1b73b7',
  twitter: '#52a8f0',
  whatsAppGreen: '#25D366',
  socialiePink: '#FC2361',
  socialiePinkHover: '#FF3D71',
  socialieBlue: '#5CC7EA',
  badgeBlue: '#1c90ff',
  success: '#3FAD7A',
};

const named = {
  bodyText: primitives.grey4,
  bodyBg: primitives.grey9,
  border: primitives.grey7,
  boxShadow: 'rgba(0, 0, 0, 0.12)',
  darkBorder: primitives.grey6,
  darkerBorder: primitives.grey4,
  darkestGreyBg: primitives.grey4,
  darkGreyBg: primitives.grey6,
  darkText: primitives.grey1,
  draftBuilderBg: primitives.grey2,
  focus: primitives.grey6,
  grey: primitives.grey4,
  greyBg: primitives.grey8,
  hoverBg: rgba(primitives.grey8, 0.25),
  inverseBorder: primitives.grey3,
  messageBg: '#36a2eb',
  lightBorder: primitives.grey8,
  lighterText: primitives.grey6,
  lightIcon: primitives.grey6,
  lightText: primitives.grey5,
  link: primitives.blue,
  primary: primitives.socialiePink,
  primaryHover: primitives.socialiePinkHover,
};

const COLORS = { ...primitives, ...named };

export default COLORS;
