import { useEffect } from 'react';
import socket from 'socket';
import { UserInitiatedTask } from 'types/UserInitiatedTask';

export default function useWatchTask<T extends UserInitiatedTask>(
  taskId: string,
  onUpdate: (task: T) => void
) {
  useEffect(() => {
    const handleTaskUpdated = async (task: T) => {
      console.log(task);
      if (`${task.id}` === taskId) {
        onUpdate(task);
      }
    };

    socket.on('taskUpdated', (payload: { task: T }) =>
      handleTaskUpdated(payload.task)
    );

    return () => {
      socket.removeListener('taskUpdated', handleTaskUpdated);
    };
  }, [taskId, onUpdate]);
}
