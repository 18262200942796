import React, { useState } from 'react';
import { ErrorDebug, Form } from 'components';
import { useIntl } from 'hooks';
import gql from 'graphql-tag';
import RelayConnection from 'types/RelayConnection';
import { useDebounce } from 'use-debounce';
import { useQuery, useApolloClient } from 'react-apollo';
import { mapNodes } from 'helpers';
import SelectOption from 'types/SelectOption';
import { default as groupsImage } from 'images/group-avatar.png';

const QUERY = gql`
  query PublishersAndTags(
    $publishersFilter: JSON
    $publisherTagsFilter: JSON
    $limit: Int = 50
  ) {
    publishers(filter: $publishersFilter, first: $limit) {
      edges {
        node {
          id
          name
          image
        }
      }
    }

    publisherTags(filter: $publisherTagsFilter, first: $limit) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

interface QueryData {
  publishers: RelayConnection<{ id: number; name: string; image: string }>;
  publisherTags: RelayConnection<{ id: number; name: string }>;
}

export interface AsyncPublishersAndTagsSelectValue {
  publishers: { value: number; label: string; image?: string }[];
  publisherTags: { value: number; label: string }[];
}

interface Props {
  value: AsyncPublishersAndTagsSelectValue;
  onChange: (changes: AsyncPublishersAndTagsSelectValue) => void;
  menuPlacement?: React.ComponentProps<typeof Form.Select>['menuPlacement'];
}

export default function AsyncPublishersAndTagsSelect(props: Props) {
  const { value, onChange } = props;
  const { t } = useIntl();
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 250);

  const { data, loading, error } = useQuery<QueryData>(QUERY, {
    variables: {
      publishersFilter: {
        name: debouncedValue,
        sortField: 'publishers.displayName',
        sortDirection: 'ASC',
      },
      publisherTagsFilter: {
        keywords: debouncedValue,
        sortField: 'name',
        sortDirection: 'ASC',
      },
      limit: 50,
    },
    skip: !inputValue,
  });

  if (error) return <ErrorDebug error={error} />;

  let publishers = mapNodes(data?.publishers?.edges ?? []);
  let publisherTags = mapNodes(data?.publisherTags?.edges ?? []);

  const options = [
    {
      label: t('PublishersAndTagsSelect__Publishers'),
      options: publishers.map((p) => ({
        value: `publisher-${p.id}`,
        label: p.name,
        image: p.image,
      })),
    },
    {
      label: t('PublishersAndTagsSelect__PublisherTags'),
      options: publisherTags.map((pt) => ({
        value: `publisherTag-${pt.id}`,
        label: pt.name,
        image: groupsImage,
      })),
    },
  ];

  // Assuming we get back values like:
  // [
  //   { value: 'publisher-10', label: 'Foo' },
  //   { value: 'publisherTag-3', label: 'Bar'}
  // ]
  // Split them up so that we can separately update the publishers and
  // publisherTags values.  This allows us to keep publishers and publisherTags
  // in the same Select
  const handleChange = (values: Array<SelectOption>) => {
    const updates: AsyncPublishersAndTagsSelectValue = {
      publishers: [],
      publisherTags: [],
    };

    values.forEach((v) => {
      if (v.value.startsWith('publisher-')) {
        updates.publishers.push({
          ...v,
          value: parseInt(v.value.replace('publisher-', ''), 10),
        });
      } else if (v.value.startsWith('publisherTag-')) {
        updates.publisherTags.push({
          ...v,
          value: parseInt(v.value.replace('publisherTag-', ''), 10),
        });
      }
    });

    onChange(updates);
  };

  // Merge the separate publishers and publisherTags values into one
  const combinedValue = [
    ...value.publishers.map((v) => ({ ...v, value: `publisher-${v.value}` })),
    ...value.publisherTags.map((v) => ({
      ...v,
      value: `publisherTag-${v.value}`,
      image: groupsImage,
    })),
  ];

  return (
    <Form.Select
      isMulti
      complexValues
      onChange={handleChange}
      value={combinedValue}
      options={options.filter((option) => !!option.label)}
      onInputChange={(val) => setInputValue(val)}
      inputValue={inputValue}
      isClearable={false}
      placeholder={t('PublishersAndTagsSelect__Placeholder')}
      noOptionsMessage={({ inputValue }) =>
        loading
          ? t('Global__Loading')
          : inputValue
          ? t('Global__NoResults')
          : t('Global__TypeToSearch')
      }
    />
  );
}

// Components using the AsyncPublishersAndTagsSelect component will occasionally
// need to fetch publishers and publisher tags by ID, for example if the
// component is being used within a filter and the user arrives at the page with
// a set of publishers and publisher tags already selected via the URL query
// string. This hook will first try to pull the publishers and publisher tags
// out of the cache and then fetch them only if they are not found.
export function usePublishersAndPublisherTagsById({
  publisherIds,
  publisherTagIds,
}: {
  publisherIds: number[];
  publisherTagIds: number[];
}) {
  const client = useApolloClient();
  const cachedPublishers = publisherIds
    .map((id) =>
      client.readFragment({
        id: `Publisher:${id}`,
        fragment: gql`
          fragment Publisher on Publisher {
            id
            name
            image
          }
        `,
      })
    )
    .filter(Boolean);

  const cachedPublisherTags = publisherTagIds
    .map((id) =>
      client.readFragment({
        id: `PublisherTag:${id}`,
        fragment: gql`
          fragment PublisherTag on PublisherTag {
            id
            name
          }
        `,
      })
    )
    .filter(Boolean);

  const missingPublisherIds = publisherIds.filter(
    (id) => !cachedPublishers.find((p) => p.id === id)
  );
  const missingPublisherTagIds = publisherTagIds.filter(
    (id) => !cachedPublisherTags.find((pt) => pt.id === id)
  );

  const { data, loading, error } = useQuery<QueryData>(QUERY, {
    variables: {
      publishersFilter: {
        id: missingPublisherIds,
      },
      publisherTagsFilter: {
        id: missingPublisherTagIds,
      },
      limit: null,
    },
    skip: !missingPublisherIds.length && !missingPublisherTagIds.length,
  });

  const loadedPublishers = mapNodes(data?.publishers?.edges ?? []);
  const loadedPublisherTags = mapNodes(data?.publisherTags?.edges ?? []);

  const publishers: Array<{ id: number; name: string; image?: string }> = [
    ...cachedPublishers,
    ...loadedPublishers,
  ];

  const publisherTags: Array<{ id: number; name: string }> = [
    ...cachedPublisherTags,
    ...loadedPublisherTags,
  ];

  return { publishers, publisherTags, loading, error };
}
