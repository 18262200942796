import React from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import { colors } from 'styles/theme';

interface Props {
  publisher: {
    image?: string;
    name?: string;
    online?: boolean;
  };
}

const ActiveIndicator = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
  border: 2px solid ${colors.white};
  border-radius: 50%;
  background: ${colors.darkGreyBg};
  width: 100%;
  height: 100%;

  ${(props: any) => props.online && `background: ${colors.green};`};
` as any;

const PublisherAvatar = ({ publisher }: Props) => (
  <Avatar
    src={publisher.image}
    name={publisher.name}
    theme={{ color: true }}
    renderSubIcon={() => <ActiveIndicator online={publisher.online} />}
  />
);

export default PublisherAvatar;
