import React, { useEffect, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from 'styles/theme';
import { IconPerson } from 'icons';
import { useStyledTheme } from 'hooks';
import { colorForValue, useRainbowColor } from 'components/RainbowGroup';

interface Props {
  src?: string;
  name?: string;
  theme?: {
    dark?: boolean;
    noBorder?: boolean;
    white?: boolean;
    rainbow?: true; // Uses rainbow colors in order
    color?: boolean; // Uses colorForValue to determine color based on name
  };
  renderSubIcon?: () => React.ReactNode;
  noBorder?: boolean;
  noBg?: boolean;
}

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.border};
  border-radius: 50%;
  background-position: 50% 50%;
  background-color: ${colors.darkGreyBg};
  width: 100%;
  height: 100%;
  text-align: center;

  a > &,
  a:visited > & {
    border-color: ${colors.border};
  }

  svg {
    width: 70%;
    height: 70%;
    color: ${colors.white};
  }

  ${(props: any) => props.noBg && 'background-color: transparent'};
  ${(props: any) => props.noBorder && 'border-color: transparent'};
  ${(props: any) => props.theme.dark && `
    background-color: ${colors.grey4};
    border-color: ${colors.grey4};
  `};

  ${(props: any) => props.theme.white && !props.missing && `
    border-color: ${colors.border};
    background-color: ${colors.white};
  `}

  ${(props: any) => props.theme.noBorder && 'border: none'};

  ${(props: any) => (props.theme.rainbow || props.theme.color) && `
    border: none;
    color: ${colors.white};
  `};

` as any;

const SubIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  width: 33.333%;
  height: 33.333%;
`;

const Avatar = (props: Props) => {
  const { src, renderSubIcon, theme, name, ...styleProps } = props;
  const [isImageBroken, setIsImageBroken] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [width, setWidth] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const rainbowColor = useRainbowColor({ skip: !theme?.rainbow });

  useEffect(() => {
    const setWrapperWidth = () => {
      if (wrapperRef.current) {
        const currentWidth = wrapperRef.current.clientWidth;
        if (currentWidth !== width) {
          setWidth(currentWidth);
        }
      }
    };

    setWrapperWidth();
  });

  const hasImage = !!src && !isImageBroken;

  return (
    <ThemeProvider theme={{ ...theme }}>
      <Wrapper
        data-testid="avatar"
        ref={wrapperRef}
        missing={!hasImage}
        {...styleProps}
        style={{
          backgroundColor: isImageLoaded
            ? 'transparent'
            : theme?.rainbow
            ? rainbowColor
            : theme?.color
            ? colorForValue(name || '')
            : undefined,
        }}
      >
        <img
          data-testid="avatar-img"
          src={src}
          className="absolute inset-0 z-2 h-full w-full rounded-full bg-white object-cover"
          onLoad={() => setIsImageLoaded(true)}
          onError={() => setIsImageBroken(true)}
          style={{ display: isImageLoaded ? 'block' : 'none' }}
          alt={name || 'Avatar'}
        />
        <Fallback name={name} width={width} />
        {renderSubIcon && <SubIcon>{renderSubIcon()}</SubIcon>}
      </Wrapper>
    </ThemeProvider>
  );
};

export default Avatar;

interface FallbackProps {
  name?: string;
  width: number;
}

function Fallback({ name, width }: FallbackProps) {
  const theme = useStyledTheme();
  if (!name) return <IconPerson />;
  const initials = name
    .split(' ')
    .slice(0, 2)
    .map((s) => s.replace(/^[^a-zA-z\\?]+/, '').substring(0, 1))
    .join('');
  if (!initials) return <IconPerson />;

  const textColor = theme.white ? 'text-dark' : 'text-white';
  const fontSize = Math.round(width * 0.5);
  const baselineOffset = fontSize > 20 ? 1 : 0.5;
  return (
    <div
      data-testid="avatar-initials"
      className={`absolute inset-0 z-1 flex h-full w-full items-center justify-center font-semibold uppercase ${textColor}`}
      style={{ fontSize: `${fontSize}px` }}
    >
      <span style={{ position: 'relative', top: `-${baselineOffset}px` }}>
        {initials}
      </span>
    </div>
  );
}
