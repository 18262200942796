import React from 'react';
import without from 'lodash/without';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import { FilterPills } from 'components';
import { useIntl, useCurrentUser } from 'hooks';
import MediaLibraryFilterData from 'types/MediaLibraryFilterData';
import { usePublishersById } from 'components/AsyncPublisherSelect';
import { usePublishersAndPublisherTagsById } from 'components/AsyncPublishersAndTagsSelect';

interface Props {
  loading: boolean;
  filterData?: MediaLibraryFilterData;
  filter: MediaLibraryFilter;
  updateFilter: (changes: Partial<MediaLibraryFilter>) => void;
  isFiltered: boolean;
  defaultFilter: MediaLibraryFilter;
}

export default function MediaLibraryFilterPills(props: Props) {
  const {
    loading,
    filterData,
    filter,
    updateFilter,
    isFiltered,
    defaultFilter,
  } = props;

  const { t } = useIntl();

  const currentUser = useCurrentUser();

  const { publishers, publisherTags } = usePublishersAndPublisherTagsById({
    publisherIds: filter.publishers,
    publisherTagIds: filter.publisherTags,
  });

  const { publishers: submitters } = usePublishersById(filter.submitters);
  const { publishers: suggestionRecipients } = usePublishersById(
    filter.suggestionRecipients
  );

  if (loading || !filterData || !filterData.currentSuggester || !isFiltered) {
    return null;
  }

  const suggestionAlbums = filterData.suggestionAlbums;

  const suggesters = [
    currentUser.currentSuggester!,
    ...currentUser.currentSuggester!.children,
  ];

  return (
    <FilterPills
      onClearFilters={() =>
        updateFilter({ ...defaultFilter, query: filter.query })
      }
    >
      {filter.sources.map((source) => (
        <FilterPills.FilterPill
          key={source}
          label={t(`Integrations--${source}`)}
          onRemove={() => {
            // Clear uploaders if the "Direct Upload" source is removed
            if (source === 'socialie') {
              updateFilter({
                sources: without(filter.sources, source),
                uploaders: [],
              });
            } else {
              updateFilter({ sources: without(filter.sources, source) });
            }
          }}
        />
      ))}

      <FilterPills.SuggesterFilterPills
        suggesters={suggesters}
        value={filter.suggesters}
        msg="MediaLibraryFilterPills__OwnedBy"
        onRemove={(id) =>
          updateFilter({ suggesters: without(filter.suggesters, id) })
        }
      />

      <FilterPills.SuggesterFilterPills
        suggesters={suggesters}
        value={filter.suggesterPublishers}
        msg="MediaLibraryFilterPills__FeaturingPublishersFrom"
        onRemove={(id) =>
          updateFilter({ suggesterPublishers: without(filter.suggesters, id) })
        }
      />

      {filter.mediaType.map((type) => (
        <FilterPills.FilterPill
          key={type}
          label={t(`MediaLibraryMediaTypeFilter__${type}`)}
          onRemove={() =>
            updateFilter({ mediaType: without(filter.mediaType, type) })
          }
        />
      ))}
      {filter.suggested !== '2' && (
        <FilterPills.FilterPill
          label={t(`MediaLibrarySuggestedFilter__Value--${filter.suggested}`)}
          onRemove={() => updateFilter({ suggested: '2' })}
        />
      )}

      {filter.mediaAppearancesOnly && (
        <FilterPills.FilterPill
          label={t('MediaLibraryMediaAppearancesOnlyFilter__Label')}
          onRemove={() => updateFilter({ mediaAppearancesOnly: null })}
        />
      )}

      <FilterPills.PublisherFilterPills
        value={filter.publishers}
        publishers={publishers}
        msg="MediaLibraryFilterPills__Featuring"
        onRemove={(id: number) =>
          updateFilter({ publishers: without(filter.publishers, id) })
        }
      />

      {filter.publisherTags.map((id) => {
        const tag = publisherTags.find((t) => Number(t.id) === Number(id));
        if (!tag) return null;

        return (
          <FilterPills.FilterPill
            key={id}
            label={t('MediaLibraryFilterPills__FeaturingTag', {
              name: tag.name,
            })}
            onRemove={() =>
              updateFilter({ publisherTags: without(filter.publisherTags, id) })
            }
          />
        );
      })}

      <FilterPills.PublisherFilterPills
        value={filter.submitters}
        publishers={submitters}
        msg="MediaLibraryFilterPills__SubmittedBy"
        onRemove={(id: number) =>
          updateFilter({ submitters: without(filter.submitters, id) })
        }
      />

      <FilterPills.PublisherFilterPills
        value={filter.suggestionRecipients}
        publishers={suggestionRecipients}
        msg="MediaLibraryFilterPills__SuggestedTo"
        onRemove={(id: number) =>
          updateFilter({
            suggestionRecipients: without(filter.suggestionRecipients, id),
          })
        }
      />

      <FilterPills.DateRangeFilterPill
        value={filter.createdAt}
        defaultValue={defaultFilter.createdAt}
        onRemove={() => updateFilter({ createdAt: defaultFilter.createdAt })}
      />

      {filter.suggestionAlbums.map((id) => {
        const album = suggestionAlbums.find((t) => Number(t.id) === Number(id));
        if (!album) return null;
        return (
          <FilterPills.FilterPill
            key={id}
            label={album.name}
            onRemove={() =>
              updateFilter({
                suggestionAlbums: without(filter.suggestionAlbums, id),
              })
            }
          />
        );
      })}

      {filter.uploaders?.map((id) => {
        const uploader = filterData?.currentSuggester.uploaders.find(
          (u) => u.id === id
        );
        if (!uploader) return null;
        return (
          <FilterPills.FilterPill
            key={id}
            label={t('MediaLibraryFilterPills_Uploader', {
              name: uploader.name,
            })}
            onRemove={() =>
              updateFilter({ uploaders: without(filter.uploaders, id) })
            }
          />
        );
      })}

      {filter.batchDownload && (
        <FilterPills.FilterPill
          label={t('MediaLibraryMediaAppearancesOnlyFilter__BatchDownload', {
            key: filter.batchDownload,
          })}
          onRemove={() => updateFilter({ batchDownload: '' })}
        />
      )}
    </FilterPills>
  );
}
