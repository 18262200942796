import { useContext, useRef } from 'react';
import React from 'react';
export const rainbowColors = [
  '#007AFF',
  '#8374F5',
  '#FF4A4A',
  '#F78219',
  '#A550A7',
  '#F74F9E',
  '#FFBA69',
  '#FF708B',
  '#FFC600',
  '#C23728',
  '#3E3687',
  '#5740FF',
  '#00B2A7',
  '#09A2FF',
  '#E14B31',
  '#2DCDFF',
  '#9EA8BD',
  '#D7658B',
  '#00E1E1',
  '#DE6E56',
  '#363445',
  '#54BEBE',
  '#00AD3B',
  '#C80064',
  '#9FB6C4',
  '#DF979E',
  '#76C8C8',
  '#0038C5',
  '#00D749',
];

const RainbowContext = React.createContext<{
  getNextColor: () => string | null;
}>({
  getNextColor: () => null,
});

export const RainbowGroup: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Start from a random color
  const currentIndexRef = useRef(
    Math.floor(Math.random() * rainbowColors.length)
  );

  const getNextColor = () => {
    const color = rainbowColors[currentIndexRef.current];
    currentIndexRef.current =
      (currentIndexRef.current + 1) % rainbowColors.length;
    return color;
  };

  return (
    <RainbowContext.Provider value={{ getNextColor }}>
      {children}
    </RainbowContext.Provider>
  );
};

export const useRainbowColor = ({ skip = false }: { skip?: boolean } = {}) => {
  const { getNextColor } = useContext(RainbowContext);
  const colorRef = useRef<string | null>(null);

  if (!skip && colorRef.current === null) {
    colorRef.current = getNextColor();
  }

  return skip ? null : colorRef.current;
};

export const colorForValue = (value: string | number) => {
  const hash = value
    .toString()
    .split('')
    .reduce((acc, char) => {
      acc = (acc << 5) - acc + char.charCodeAt(0);
      return acc & acc;
    }, 0);

  return rainbowColors[Math.abs(hash) % rainbowColors.length];
};
