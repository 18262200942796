import { DateHeading, InfiniteScrollList, RenderIfVisible } from 'components';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import AttachmentType from 'types/Attachment';
import { Attachment } from 'components';
import { MediaLibraryLoading } from 'components';

interface Props {
  attachments: AttachmentType[];
  isInitialLoad: boolean;
  isFetchingMore: boolean;
  loadNextPage: () => void;
  isAttachmentAlbumMedia?: boolean;
  hasNextPage?: boolean;
  thumbnailStyle: 'compact' | 'full';
}

const AttachmentList = (props: Props) => {
  const {
    attachments,
    isInitialLoad,
    isFetchingMore,
    loadNextPage,
    hasNextPage,
    thumbnailStyle,
    isAttachmentAlbumMedia = false,
  } = props;

  if (isInitialLoad) return <MediaLibraryLoading />;

  if (thumbnailStyle === 'compact') {
    return (
      <InfiniteScrollList
        loading={isFetchingMore}
        loadNextPage={loadNextPage}
        hasNextPage={hasNextPage}
        threshold={900}
      >
        <div className="AttachmentList AttachmentList--compact">
          {uniqBy(attachments, 'id').map((attachment) => (
            <RenderIfVisible
              key={attachment.id}
              defaultHeight={240}
              wrapperProps={{
                className: 'AttachmentThumbnail group',
              }}
              renderPlaceholder={() => <></>}
            >
              <Attachment
                key={attachment.id}
                attachment={attachment}
                isAttachmentAlbumMedia={isAttachmentAlbumMedia}
              />
            </RenderIfVisible>
          ))}
        </div>
      </InfiniteScrollList>
    );
  }

  const attachmentsByDate: {
    [key: string]: AttachmentType[];
  } = attachments.reduce((result, a) => {
    const key = new Date(a.createdAt).setHours(0, 0, 0, 0);
    return {
      ...result,
      [key]: [...(result[key] || []), a],
    };
  }, {});

  return (
    <InfiniteScrollList
      loading={isFetchingMore}
      loadNextPage={loadNextPage}
      hasNextPage={hasNextPage}
      threshold={2500}
    >
      {Object.keys(attachmentsByDate).map((key) => (
        <div
          key={key}
          className="mb-2"
          data-qa={`uploaded-date-${
            new Date(parseInt(key, 10)).toISOString().split('T')[0]
          }`}
        >
          <DateHeading date={parseInt(key, 10)} />

          <div className="AttachmentList AttachmentList--full">
            {uniqBy(attachmentsByDate[key], 'id').map((attachment) => {
              let aspectRatio = attachment.aspectRatio;
              if (!aspectRatio) return null;

              if (aspectRatio < 0.5625) aspectRatio = 0.5625;
              if (aspectRatio > 1.777777778) aspectRatio = 1.777777778;
              const reverseAspectRatio = 1 / aspectRatio;

              const style = {
                '--reverse-aspect-ratio': reverseAspectRatio,
                '--aspect-ratio-percent': `${aspectRatio * 100}%`,
              } as React.CSSProperties;

              return (
                <RenderIfVisible
                  key={attachment.id}
                  defaultHeight={240}
                  wrapperProps={{
                    className: 'AttachmentThumbnail group',
                    style,
                  }}
                  renderPlaceholder={() => <></>}
                >
                  <Attachment
                    key={attachment.id}
                    attachment={attachment}
                    isAttachmentAlbumMedia={isAttachmentAlbumMedia}
                  />
                </RenderIfVisible>
              );
            })}
          </div>
        </div>
      ))}
    </InfiniteScrollList>
  );
};

export default AttachmentList;
