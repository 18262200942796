import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { createPortal } from 'react-dom';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FilterContext } from 'screens/MediaLibrary/screens/MediaLibraryIndex';
import Attachment from 'types/Attachment';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import ATTACHMENT_QUERY from 'graphql/queries/attachment.graphql';
import Loader from 'components/Loader';
import ErrorDebug from 'components/ErrorDebug';
import { Slider } from 'components/Slider';
import { IconClose, IconPlay } from 'icons';
import Button from 'components/Button';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { flashMessage, routeFor, routes } from 'helpers';
import clsx from 'clsx';
import gql from 'graphql-tag';
import { useAppSelector } from 'redux/store';
import { TrimVideoTask } from 'types/UserInitiatedTask';
import Modal from 'components/Modal';
import { useIntl } from 'hooks';
import useWatchTask from 'hooks/useWatchTask';
import LinearProgress from 'components/LinearProgress';

interface QueryData {
  attachment: Attachment;
  nextAttachment?: { id: React.ReactText };
  prevAttachment?: { id: React.ReactText };
}

interface QueryVars {
  id: string;
  filter: MediaLibraryFilter;
}

export default function AttachmentEditModal() {
  const contentRef = useRef<HTMLDivElement>(null);
  const { filter } = useContext(FilterContext);
  const match = useRouteMatch();
  const attachmentIdInt = Number(match.params.id);
  const [taskId, setTaskId] = useState<string | null>(null);
  const { data, loading, error } = useQuery<QueryData, QueryVars>(
    ATTACHMENT_QUERY,
    {
      variables: {
        filter,
        id: match.params.id,
      },
    }
  );

  useEffect(() => {
    const currentEl = contentRef.current;
    if (currentEl) disableBodyScroll(currentEl, { reserveScrollBarGap: true });

    return () => {
      if (currentEl) enableBodyScroll(currentEl);
    };
  }, []);

  if (taskId) {
    return <PostTrimModal taskId={taskId} />;
  }

  return createPortal(
    <div
      ref={contentRef}
      className="fixed inset-0 z-[9999] overflow-hidden bg-grey1"
    >
      {loading ? (
        <>
          <Header attachmentId={attachmentIdInt} />
          <Loader />
        </>
      ) : error ? (
        <>
          <Header attachmentId={attachmentIdInt} />
          <ErrorDebug error={error} />
        </>
      ) : data?.attachment ? (
        <Editor attachment={data.attachment} setTaskId={setTaskId} />
      ) : null}
    </div>,
    document.body
  );
}

interface EditorProps {
  attachment: Attachment;
  setTaskId: (taskId: string) => void;
}

const MUTATION = gql`
  mutation TrimVideo(
    $id: ID!
    $startTime: Int!
    $endTime: Int!
    $socketId: String
  ) {
    trimVideo(
      id: $id
      startTime: $startTime
      endTime: $endTime
      socketId: $socketId
    ) {
      task {
        id
        status
        progress
        output
      }
      errors
    }
  }
`;

function Editor({ attachment, setTaskId }: EditorProps) {
  const { t } = useIntl();
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const playheadRef = useRef<HTMLDivElement>(null);
  const animationFrameRef = useRef<number | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const socketId = useAppSelector((state) => state.socket.id);
  const [trimVideo, { loading }] = useMutation(MUTATION, {
    variables: { id: attachment.id, startTime, endTime, socketId },
    onCompleted: (data) => {
      if (data.trimVideo.errors) {
        flashMessage('Global__UnexpectedError', { style: 'error' });
      } else {
        setTaskId(data.trimVideo.task.id);
      }
    },
  });
  // Use requestAnimationFrame to update the playhead position when
  // the video is playing
  useEffect(() => {
    const updatePlayhead = () => {
      if (!videoRef.current || !playheadRef.current || !isVideoPlaying) return;

      const position = videoRef.current.currentTime / videoRef.current.duration;
      playheadRef.current.style.left = `${position * 100}%`;
      animationFrameRef.current = requestAnimationFrame(updatePlayhead);
    };

    if (isVideoPlaying) {
      animationFrameRef.current = requestAnimationFrame(updatePlayhead);
    }

    return () => {
      if (animationFrameRef.current)
        cancelAnimationFrame(animationFrameRef.current);
    };
  }, [isVideoPlaying]);

  return (
    <div className="flex h-full w-full flex-col items-center overflow-hidden">
      <Header attachmentId={attachment.id}>
        <div className="text-16 text-light">{attachment.mediaFilename}</div>
        <Button
          className="ml-auto mr-2"
          inlineBlock
          filledBg
          onClick={() => trimVideo()}
          loading={loading}
        >
          {t('AttachmentEditModal__TrimButton')}
        </Button>
      </Header>
      <div className="group relative w-full flex-1 overflow-hidden p-8">
        <video
          ref={videoRef}
          src={attachment.mediaUrl}
          controls={false}
          className="h-full w-full object-contain"
          onPause={() => setIsVideoPlaying(false)}
          onLoadedMetadata={(e) => {
            const duration = Math.floor(e.currentTarget.duration * 1000);
            setVideoDuration(duration);
            setEndTime(duration);
          }}
          onTimeUpdate={() => {
            if (!videoRef.current) return;
            if (videoRef.current.currentTime * 1000 >= endTime) {
              videoRef.current.pause();
              setIsVideoPlaying(false);
            }
          }}
          onPlay={() => {
            setIsVideoPlaying(true);
            if (!videoRef.current) return;
            if (videoRef.current.currentTime * 1000 < startTime) {
              videoRef.current.currentTime = startTime / 1000;
            }
            if (videoRef.current.currentTime * 1000 >= endTime) {
              videoRef.current.currentTime = startTime / 1000;
            }
          }}
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <button
            className="opacity-0 transition-opacity duration-100 group-hover:opacity-75"
            onClick={() => {
              if (!videoRef.current) return;
              if (videoRef.current.paused) {
                videoRef.current.play();
              } else {
                videoRef.current.pause();
              }
            }}
          >
            <IconPlay className="h-12 w-12 text-white" />
          </button>
        </div>
      </div>

      {!!videoDuration && (
        <div className="mb-16 mt-4 w-86 flex-shrink-0 rounded-lg border-0 border-t border-solid border-t-grey3 bg-grey2 px-3 pb-1.5 pt-2.5 shadow-sm">
          <div className="relative">
            <Slider
              defaultValue={[0, videoDuration]}
              max={videoDuration}
              minStepsBetweenThumbs={2000}
              step={1}
              value={[startTime, endTime]}
              onValueChange={(values) => {
                if (values[0] !== startTime) {
                  setStartTime(values[0]);
                  videoRef.current!.currentTime = values[0] / 1000;
                  playheadRef.current!.style.left = `${
                    (values[0] / videoDuration) * 100
                  }%`;
                } else {
                  setEndTime(values[1]);
                  videoRef.current!.currentTime = values[1] / 1000;
                  playheadRef.current!.style.left = `${
                    (values[1] / videoDuration) * 100
                  }%`;
                }
              }}
            />

            <div
              className={clsx('absolute left-0 top-0 h-0.5 w-px bg-white')}
              ref={playheadRef}
            />
          </div>

          <div className="mt-2 grid grid-cols-[1fr,3fr,1fr] items-center text-light">
            <div className="text-center">
              {t('AttachmentEditModal__Start')}{' '}
              <span className="text-white">{mmSS(startTime)}</span>
            </div>
            <div className="border-0 border-x border-solid border-x-grey3 py-1 text-center text-white">
              {mmSS(endTime - startTime)}
            </div>

            <div className="text-center">
              {t('AttachmentEditModal__End')}{' '}
              <span className="text-white">{mmSS(endTime)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

interface HeaderProps {
  children?: React.ReactNode;
  attachmentId: number;
}

function Header({ children, attachmentId }: HeaderProps) {
  return (
    <div className="flex h-8 w-full flex-shrink-0 items-center justify-end border-0 border-b border-solid border-grey3 py-1 pl-3 pr-1">
      {children}
      <Link
        to={routeFor(routes.mediaLibrary.show, attachmentId)}
        className="h-4 w-4 p-1"
      >
        <IconClose className="block h-full w-full text-white" />
      </Link>
    </div>
  );
}

function mmSS(ms: number): string {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  const milliseconds = ((ms % 1000) / 1000).toFixed(2).slice(2);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}.${milliseconds}`;
}

function PostTrimModal({ taskId }: { taskId: string }) {
  const [task, setTask] = useState<TrimVideoTask | null>(null);
  useWatchTask<TrimVideoTask>(taskId, setTask);
  const history = useHistory();
  const { t } = useIntl();
  const currentStep = task?.output?.step || 'WAITING';
  const progress = task?.progress ?? 0;

  return (
    <Modal
      isOpen
      theme={{ medium: true }}
      onRequestClose={() => history.push(routeFor(routes.mediaLibrary.index))}
      renderHeading={t('AttachmentEditModal__TaskModalHeading')}
      contentLabel={t('AttachmentEditModal__TaskModalHeading')}
    >
      <LinearProgress value={progress} />

      <div className="text-left text-bodyText">
        {t(`AttachmentEditModal__TrimStep--${currentStep}`)}
      </div>

      {progress < 100 && (
        <p className="mt-3 rounded-lg bg-grey7 px-2 py-1 text-left leading-tight text-dark">
          {t('AttachmentEditModal__TrimModalDescription')}
        </p>
      )}

      <div className="mt-4 flex items-center justify-end gap-2">
        {task?.output?.video_id && (
          <Button
            to={routeFor(routes.mediaLibrary.show, task.output.video_id)}
            filledBg
            inlineBlock
          >
            {t('AttachmentEditModal__ViewTrimmedVideo')}
          </Button>
        )}
        <Button
          inlineBlock
          filledGreyBg
          onClick={() => history.push(routeFor(routes.mediaLibrary.index))}
        >
          {t('Global__Close')}
        </Button>
      </div>
    </Modal>
  );
}
