fragment SuggesterFields on Suggester {
  id
  accountName
  brand
  paused
  avgRawParticipationRate
  avgCurrentFollowerCount
  onboardingCompleted

  contentIntegrations {
    id
    username
    integrationType
    oauthIdentityId
    invalidAccount
    label
    contentRoutingRules {
      id
      invalidPath
    }
    isNewPhotoShelterIntegration
  }

  accountConfiguration {
    id
    allowWorldwideSms
    domesticSmsCountryCode
    enableStories
    enableTwitterUploadLong
    enableFacialRecognition
    primaryColor
    onboardingPlatformBlacklist
    contactProtocolBlacklist
    countryCodeWhitelist
    logoUrl
    logoUrl
    logoBorderColor
    topBarBorderColor
    loginButtonBgColor
    loginButtonFgColor
    backgroundColor
    backgroundOpacity
    backgroundImageMobileUrl
    backgroundImageDesktopUrl
    backgroundImageMobileOffset
    backgroundImageDesktopOffset
    logoNormal
    logoEmailWidth
    logoEmailHeight
    creditFacebook
    creditInstagram
    creditTwitter
    enableWhatsApp
    whatsAppSenderSid
    enableStories
    requireMfa
    maxAthletes
    remainingAthletes
    maxSuggesters
    maxSuggesterUploaders
    gettyForPublishers {
      id
      enableForAll
    }
  }
}
