import React, { useContext } from 'react';
import useKey from '@rooks/use-key';
import { RouteComponentProps } from 'react-router';
import {
  FilterContext,
  SelectableContext,
} from 'screens/MediaLibrary/screens/MediaLibraryIndex';
import { useQuery } from '@apollo/react-hooks';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import Attachment from 'types/Attachment';
import ATTACHMENT_QUERY from 'graphql/queries/attachment.graphql';
import { routes, routeFor } from 'helpers';
import { AttachmentModal, Checkbox } from 'components';
import { SuggestAttachmentButton } from 'components';
import { ThemeProvider } from 'styled-components';
import { Link } from 'react-router-dom';
import { IconTrim } from 'icons';
import { useIntl } from 'hooks';
import useFeatureFlags from 'hooks/useFeatureFlags';

type Props = RouteComponentProps<{ id: string }>;

interface QueryData {
  attachment: Attachment;
  nextAttachment?: { id: React.ReactText };
  prevAttachment?: { id: React.ReactText };
}

interface QueryVars {
  id: string;
  filter: MediaLibraryFilter;
}

export default function AttachmentModalContainer({ match, history }: Props) {
  const { filter } = useContext(FilterContext);
  const featureFlags = useFeatureFlags();

  const attachmentIdInt = Number(match.params.id);

  const selectable = useContext(SelectableContext);
  const isSelected = selectable.isSelected(attachmentIdInt);
  const isSelectMode = !!selectable.selectedIds.length;

  const theme = { isSelected, isSelectMode };

  const { data, error } = useQuery<QueryData, QueryVars>(ATTACHMENT_QUERY, {
    variables: {
      filter,
      id: match.params.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleSelect = () => {
    selectable.toggleSelected(attachmentIdInt);
  };

  const closeModal = () =>
    history.push(`${routes.mediaLibrary.index}${window.location.search}`);

  useKey(['ArrowRight'], () => {
    if (!data || !data.prevAttachment) return;
    history.push(routeFor(routes.mediaLibrary.show, data.prevAttachment.id));
  });

  useKey(['ArrowLeft'], () => {
    if (!data || !data.nextAttachment) return;
    history.push(routeFor(routes.mediaLibrary.show, data.nextAttachment.id));
  });

  useKey(['Escape'], closeModal);
  const { t } = useIntl();

  return (
    <ThemeProvider theme={theme}>
      <AttachmentModal
        key={match.params.id}
        attachment={data && data.attachment}
        error={error}
        onRequestClose={closeModal}
        renderPrimaryAction={
          <>
            {isSelectMode && (
              <div className="absolute left-0 top-0 isolate z-10">
                <Checkbox onClick={handleSelect} />
              </div>
            )}
            <div className="flex items-center gap-0.5">
              <SuggestAttachmentButton attachmentId={match.params.id} />
              {featureFlags.videoTrimming &&
                data?.attachment?.type === 'Video' && (
                  <Link
                    to={routeFor(routes.mediaLibrary.edit, match.params.id)}
                    className="flex h-4 w-4 items-center justify-center rounded-lg border-2 border-solid border-socialiePink p-0.5 text-socialiePink"
                    title={t('AttachmentEditModal__Trim')}
                  >
                    <IconTrim />
                  </Link>
                )}
            </div>
          </>
        }
      />
    </ThemeProvider>
  );
}
