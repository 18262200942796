import React from 'react';
import { Link } from 'react-router-dom';
import { routeFor, routes } from 'helpers';
import Avatar from 'components/Avatar';
import PublisherRequest from 'types/PublisherRequest';
import { FormattedTimeAgo, RequestStatus } from 'components';
import { IconSmartSuggestion } from 'icons';
import ContentRoutingRuleJoin from 'types/ContentRoutingRuleJoin';
import Tooltip from 'components/Tooltip';
import ContentRoutingRuleJoinList from '../ContentRoutingRuleJoinList';

interface RequestItemProps {
  request: PublisherRequest;
  suggestion: {
    id: number;
    source: string;
    contentRoutingRuleSuggestions?: ContentRoutingRuleJoin[];
  };
  hideDate?: boolean;
}

export default function RequestItem({
  request,
  hideDate,
  suggestion,
}: RequestItemProps) {
  return (
    <div className="mb-1.5 flex items-center justify-between last:mb-0">
      <div className="flex items-center">
        <div className="mr-1 h-3 w-3">
          <Avatar
            src={request.publisher.image}
            name={request.publisher.name}
            theme={{ color: true }}
          />
        </div>
        <span className="flex flex-col items-start leading-tight">
          <div className="flex items-center">
            <p className="leading-tight text-dark">{request.publisher.name}</p>

            {!!suggestion.contentRoutingRuleSuggestions?.length && (
              <Tooltip
                interactive
                content={
                  <div className="p-1">
                    <ContentRoutingRuleJoinList
                      joins={suggestion.contentRoutingRuleSuggestions}
                      hideSmartSuggestionIcon
                    />
                  </div>
                }
              >
                <span>
                  <IconSmartSuggestion className="ml-0.5 block h-2 w-2 text-light" />
                </span>
              </Tooltip>
            )}
          </div>
          <div className="text-sm text-grey5">
            <FormattedTimeAgo
              timestamp={
                request.status === 'published'
                  ? request.completedAt
                  : request.sentAt
              }
            />
          </div>
        </span>
      </div>
      <Link to={routeFor(routes.tracking.show, suggestion.id)}>
        <RequestStatus request={request} hideDate={hideDate} />
      </Link>
    </div>
  );
}
