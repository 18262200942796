import Attachment from 'types/Attachment';
import InfoCard from './InfoCard';
import { useIntl } from 'hooks';
import { Link } from 'react-router-dom';
import { formatDate, routeFor, routes } from 'helpers';
import { formatLength } from 'helpers/formatNumber';
import { IconExternal } from 'icons';

interface Props {
  revisions: Attachment['revisions'];
}

export default function Revisions({ revisions = [] }: Props) {
  const { t } = useIntl();
  return (
    <InfoCard title={t('AttachmentModal__Revisions')}>
      {revisions.map((revision) => (
        <Link
          key={revision.id}
          className="group flex items-center gap-1 text-bodyText"
          to={routeFor(routes.mediaLibrary.show, revision.id)}
        >
          <img
            className="h-3.5 w-3.5 rounded-lg"
            src={revision.thumbnail}
            alt=""
          />
          <div className="bump-up-1 flex-1 text-dark">
            {formatDate(revision.createdAt)}
          </div>

          <div className="ml-auto flex flex-shrink-0">
            {revision.length && (
              <div className="bump-up-1 text-right text-light">
                {formatLength(revision.length)}
              </div>
            )}

            <div className="border-l-default ml-1.5 flex items-center pl-1.5">
              <IconExternal className="block h-2 w-2 group-hover:text-socialiePinkHover" />
            </div>
          </div>
        </Link>
      ))}
    </InfoCard>
  );
}
