import React from 'react';
import styled from 'styled-components';
import { colors, grid } from 'styles/theme';
import { IconClose } from 'icons';
import { noPropagate } from 'helpers';
import { Avatar } from 'components';

type Color =
  | 'blue'
  | 'red'
  | 'error'
  | 'teal'
  | 'darkTeal'
  | 'yellow'
  | 'lightGrey'
  | 'grey'
  | 'darkGrey'
  | 'darkerGrey'
  | 'white'
  | 'socialieBlue';

interface Props {
  color?: Color;
  onRemove?: () => void;
  removeProps?: any;
  squircle?: boolean;
  tight?: boolean;
  large?: boolean;
  small?: boolean;
  image?: string;
  className?: string;
  imageFallback?: string;
  isLoading?: boolean;
  clickable?: boolean;
  children: React.ReactNode;
}

const fgColors = {
  blue: colors.blue,
  socialieBlue: colors.white,
  red: colors.red,
  teal: colors.teal,
  darkTeal: colors.white,
  yellow: colors.yellow,
  lightGrey: colors.grey5,
  grey: colors.bodyText,
  darkGrey: colors.white,
  darkerGrey: colors.white,
  white: colors.bodyText,
  error: colors.white,
};

const bgColors = {
  blue: colors.blueBg,
  socialieBlue: colors.socialieBlue,
  red: colors.redBg,
  teal: colors.tealBg,
  darkTeal: colors.teal,
  yellow: colors.yellowBg,
  lightGrey: colors.grey9,
  grey: colors.greyBg,
  darkGrey: colors.darkestGreyBg,
  darkerGrey: colors.grey3,
  white: colors.white,
  error: colors.error,
};

const hoverColors = {
  blue: colors.blueBg,
  socialieBlue: colors.socialieBlue,
  red: colors.redBg,
  teal: colors.tealBg,
  darkTeal: colors.teal,
  yellow: colors.yellowBg,
  lightGrey: colors.grey9,
  grey: colors.grey7,
  darkGrey: colors.darkestGreyBg,
  darkerGrey: colors.grey2,
  white: colors.white,
};

// prettier-ignore
const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  border-radius: 4px;
  padding: 0 ${grid(1)};
  vertical-align: top;
  line-height: ${grid(3)};
  font-size: 13px;

  ${(props: { color: string }) => `
    background-color: ${bgColors[props.color]};
    color: ${fgColors[props.color]};
  `};

  ${(props: any) => props.clickable && `
    &:hover {
      background-color: ${hoverColors[props.color]};
      cursor: pointer;
    }
  `}

  ${(props: any) => props.color === 'white' && `
    box-shadow: inset 0 0 0 1px ${colors.border};
  `};

  ${(props: any) => props.large && `
    padding: 0 ${grid(1)};
    font-size: 16px;
    line-height: 18px;
    min-height: ${grid(3)};

    > span {
      position: relative;
      top: 1px;
    }
  `};

  ${(props: any) => props.small && `
    padding: 0 ${grid(1)};
    font-size: 12px;
    line-height: ${grid(2.5)}
    min-height: ${grid(2.5)};

    > span {
      position: relative;
      top: 1px;
    }
  `};

  ${(props: any) => props.squircle && `
    border-radius: 4px;
    line-height: ${grid(2.5)};
  `}

  ${(props: any) => props.tight && `padding: 0 ${grid(0.5)};`};

  ${(props: any) => props.hasImage && `
    padding-left: ${grid(3.5)};
    border-top-left-radius: ${grid(2)};
    border-bottom-left-radius: ${grid(2)};
  `};

  ${(props: any) => props.isLoading && 'opacity: 0.4'};

  ${(props: any) => props.canRemove && `
    padding-right: ${grid(3.5)};
  `}

  > span {
    display: flex;
    position: relative;
    top: -1px;
    align-items: center;
    flex-wrap: wrap;
  }

  > img,
  .avatar-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${grid(0.25)};
    border-radius: 50%;
    width: ${grid(2.5)};
    height: ${grid(2.5)};
  }
` as any;

const RemoveButton = styled.button`
  display: flex;
  position: absolute;
  top: calc(50% - ${grid(1)});
  right: ${grid(0.5)};
  align-items: center;
  justify-content: center;
  padding: ${grid(0.25)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.lightIcon};

  > svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: ${colors.bodyText};
  }
`;

const Pill: React.SFC<Props> = ({
  onRemove,
  children,
  image,
  imageFallback,
  removeProps,
  ...styles
}: Props) => (
  <Wrapper
    canRemove={!!onRemove}
    hasImage={!!image || !!imageFallback}
    {...styles}
  >
    {(image || imageFallback) && (
      <>
        {imageFallback ? (
          <div className="avatar-wrapper">
            <Avatar
              src={image}
              name={imageFallback}
              theme={{ color: true, noBorder: true }}
            />
          </div>
        ) : (
          <img src={image} alt="" />
        )}
      </>
    )}
    <span className="break-words py-0.5 leading-tight">{children}</span>

    {onRemove && (
      <RemoveButton
        tabIndex={-1}
        type="button"
        onClick={noPropagate(onRemove)}
        {...removeProps}
      >
        <IconClose />
      </RemoveButton>
    )}
  </Wrapper>
);

Pill.defaultProps = {
  color: 'grey',
};

export default Pill;
