import React from 'react';
import Attachment from 'types/Attachment';
import {
  Actions,
  Metadata,
  MediaAppearances,
  Notes,
  SuggestionHistory,
  PerformanceStats,
  ContentRoutingRules,
  CanMonetize,
} from './components';
import { useIntl } from 'hooks';
import ScheduledSuggestions from './components/ScheduledSuggestions';
import AttachmentModalDetailsSocialieData from './components/AttachmentModalDetailsSocialieData';
import { IconHourglass } from 'icons';
import { Link } from 'react-router-dom';
import { routeFor, routes } from 'helpers';
import { FormattedMessage } from 'react-intl';
import Revisions from './components/Revisions';

interface Props {
  attachment?: Attachment;
  isAttachmentAlbumMedia?: boolean;
  loading?: boolean;
  renderPrimaryAction?: React.ReactNode;
}

export default function AttachmentModalDetails({
  attachment,
  renderPrimaryAction,
  isAttachmentAlbumMedia = false,
}: Props) {
  const { t, formatRelative } = useIntl();

  if (!attachment) return null;
  const date = new Date(attachment.createdAt).toString();
  const hasPublishedSuggestions =
    attachment.suggestions &&
    attachment.suggestions.some((s) =>
      s.requests.some((r) => r.status === 'published')
    );

  return (
    <>
      <div className="space-between mb-2 flex items-center">
        <time
          title={date}
          dateTime={date}
          className="mr-2 block flex-1 overflow-hidden truncate text-14 text-light"
        >
          {t('AttachmentModal__Added')} {formatRelative(attachment.createdAt)}
        </time>

        <Actions
          attachment={attachment}
          isAttachmentAlbumMedia={isAttachmentAlbumMedia}
          renderPrimaryAction={renderPrimaryAction}
        />
      </div>
      {(!!attachment.title || !!attachment.caption) && (
        <div className="mb-2 text-16 leading-20 text-dark">
          {attachment.title}
          {!!attachment.caption && (
            <div className="text-13 leading-16 text-bodyText">
              {attachment.caption}
            </div>
          )}
        </div>
      )}

      {attachment.automationStatus === 'processing' && (
        <InProgress attachment={attachment} />
      )}

      {hasPublishedSuggestions && <PerformanceStats attachment={attachment} />}
      <MediaAppearances attachment={attachment} />
      <SuggestionHistory attachment={attachment} />
      <ScheduledSuggestions attachment={attachment} />
      <Metadata attachment={attachment} />
      <AttachmentModalDetailsSocialieData attachment={attachment} />

      {attachment.contentRequestSubmission && (
        <Notes contentRequestSubmission={attachment.contentRequestSubmission} />
      )}

      {attachment.revisions && attachment.revisions.length > 0 && (
        <Revisions revisions={attachment.revisions} />
      )}

      <ContentRoutingRules attachment={attachment} />

      <CanMonetize attachment={attachment} />
    </>
  );
}

function InProgress({ attachment }: { attachment: Attachment }) {
  const { t } = useIntl();

  const unlinkableSources = [
    'publisher',
    'publisher_direct_upload',
    null,
    undefined,
  ];

  return (
    <div className="mb-2 rounded-lg bg-grey3 p-1.5 leading-tight text-white">
      <p className="mb-0.5 flex items-center gap-0.5 font-bold">
        <IconHourglass className="h-2 w-2" />
        <span className="bump-up-1">{t('AttachmentModal__Processing')}</span>
      </p>
      <p className="text-12">
        <FormattedMessage
          id="AttachmentModal__ProcessingDescription"
          values={{
            rules: unlinkableSources.includes(attachment.source) ? (
              t('AttachmentModal__Rules')
            ) : (
              <Link
                className="text-white underline"
                to={routeFor(
                  routes.settings.integrations.detail,
                  attachment.source
                )}
              >
                {t('AttachmentModal__Rules')}
              </Link>
            ),
          }}
        />
      </p>
    </div>
  );
}
