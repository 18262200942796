const routes = {
  dashboard: {
    root: '/',
  },

  auth: {
    login: '/log-in',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    mfaLogin: '/log-in-2fa',
    mfaSetup: '/setup-2fa',
  },

  athlete: {
    index: '/athlete/comment-moderation',
    demographics: '/athlete/demographics',
  },

  invitation: '/invites/:code',

  onboarding: {
    root: '/get-started',
    organization: '/get-started/organization',
    brand: '/get-started/brand',
    phone: '/get-started/phone',
    socialAccounts: '/get-started/social-accounts',
    inviteCodes: '/get-started/invite-codes',
    complete: '/get-started/complete',
    facebookPageSelector: '/get-started/social-accounts/facebook-pages',
  },

  feed: {
    index: '/feed',
    insights: '/feed/insights',
  },

  tracking: {
    root: '/tracking',
    index: '/tracking',
    drafts: '/tracking/drafts',
    pending: '/tracking/pending',
    show: '/tracking/:suggestionId',
    overview: '/tracking/:suggestionId/overview',
    engagement: '/tracking/:suggestionId/engagement',
  },

  publishers: {
    root: '/publishers',
    index: '/publishers',
    tags: '/publishers/groups',
    show: '/publishers/:publisherId',
    overview: '/publishers/:publisherId/overview',
    participation: '/publishers/:publisherId/participation',
    followers: '/publishers/:publisherId/followers',
  },

  mediaLibrary: {
    root: '/media-library',
    index: '/media-library',
    show: '/media-library/:id',
    edit: '/media-library/:id/edit',
    publisherMedia: {
      index: '/media-library/publishers',
      show: '/media-library/publishers/:id',
      showMedia: '/media-library/publishers/:id/:attachmentId',
    },
    albumMedia: {
      index: '/media-library/albums',
      show: '/media-library/albums/:id',
      showMedia: '/media-library/albums/:id/:attachmentId',
    },
  },

  draftBuilder: {
    index: '/create',
    batch: '/create/batch/:batchSuggestionId',
    textOnly: '/create/text-only/:id',
    contentRequest: '/create/content-request/:id',
  },

  reports: {
    index: '/reports',
    activity: '/reports/activity',
    campaigns: '/reports/campaigns',
    tags: '/reports/tags',
    postTags: '/reports/post-tags',
    publishers: '/reports/publishers',
    publisherActivity: '/reports/publisher-activity',
    publisherTagActivity: '/reports/group-activity',
    publisherPerformance: '/reports/publisher-performance',
  },

  settings: {
    account: '/settings/account',
    announcements: '/settings/announcements',
    brand: '/settings/brand',
    brandCredits: '/settings/captions#brand-credits',
    captions: '/settings/captions',
    facebookPageSelector: '/settings/account/facebook-pages',
    gettyForPublishers: '/settings/getty-publishers',
    index: '/settings',
    integrations: {
      detail: '/settings/integrations/:integrationType',
      index: '/settings/integrations',
      logs: '/settings/integrations/:integrationType/:integrationId/logs',
      logEntry:
        '/settings/integrations/:integrationType/:integrationId/logs/:logEntryId',
    },
    manageCampaigns: '/settings/campaigns',
    manageTags: '/settings/tags',
    manageAlbums: '/settings/albums',
    notifications: '/settings/notifications',
    onboarding: '/settings/onboarding',
    password: '/settings/password',
    profile: '/settings/profile',
    dailyEmailReports: '/settings/reports',
    security: '/settings/security',
    users: '/settings/users',
    subscription: '/settings/subscription',
  },

  upload: '/upload',
};

export default routes;
