import useCurrentUser from './useCurrentUser';

interface Features {
  selfServeWhatsApp?: boolean;
  videoTrimming?: boolean;
}

export default function useFeatureFlags() {
  const user = useCurrentUser();
  try {
    return JSON.parse(user.features) as Features;
  } catch (e) {
    return {};
  }
}
